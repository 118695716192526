




















import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import {UserNotification} from "@/store/entities/usernotification";

@Component
export default class UserNotificationForm extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    private notification: UserNotification = new UserNotification();


    save() {
        (this.$refs.notifyForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: 'user/notify',
                    data: this.notification
                });
                (this.$refs.notifyForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.notifyForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            let user = Util.extend(true, {}, this.$store.state.user.editUser);
            this.notification = new UserNotification();
            this.notification.userId = user.id;
        }
    }

}
