









































import { Component, Vue,Inject, Prop,Watch } from 'vue-property-decorator';
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import User from '../../../store/entities/user'
@Component
export default class EditUser extends AbpBase{
    @Prop({type:Boolean,default:false}) value:boolean;
    user:User=new User();
    created(){
        
    }
    get roles(){
        return this.$store.state.user.roles;
    }
    save(){
        (this.$refs.userForm as any).validate(async (valid:boolean)=>{
            if(valid){
                await this.$store.dispatch({
                    type:'user/update',
                    data:this.user
                });
                (this.$refs.userForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input',false);
            }
        })
    }
    cancel(){
        (this.$refs.userForm as any).resetFields();
        this.$emit('input',false);
    }
    visibleChange(value:boolean){
        if(!value){
            this.$emit('input',value);
        }else{
            this.user=Util.extend(true,{},this.$store.state.user.editUser);
        }
    }
    userRule={
        userName:[{required: true,message:this.L('FieldIsRequired',undefined,this.L('UserName')),trigger: 'blur'}],
        name:[{required:true,message:this.L('FieldIsRequired',undefined,this.L('Name')),trigger: 'blur'}],
        surname:[{required:true,message:this.L('FieldIsRequired',undefined,this.L('Surname')),trigger: 'blur'}],
        emailAddress:[{required:true,message:this.L('FieldIsRequired',undefined,this.L('Email')),trigger: 'blur'},{type: 'email'}],
    }
}
