










































import {Component, Prop} from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import JobUserNotify from "@/store/entities/jobusernotify";
import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";

@Component
export default class JobUserNotifyForm extends AbpBase {
    private jobUserNotify: JobUserNotify = new JobUserNotify();
    @Prop({type: Boolean, default: false}) value: boolean;
    spinShow: boolean = false;
    private users: any;
    private jobs: any;
    private nodes: any;

    data() {
        return {
            users: [],
            jobs: [],
            nodes: []
        }
    };

    async save() {
        try {
            this.spinShow = true;
            this.jobs = await this.$store.dispatch('job/notify', this.jobUserNotify);
        } finally {
            this.spinShow = false;
        }

        (this.$refs.notifyForm as any).resetFields();
        this.$emit('save-success');
        this.$emit('input', false);
    }

    cancel() {
        (this.$refs.notifyForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            this.jobUserNotify = new JobUserNotify();
        }
    }

    async loadUsers(query) {
        this.users = await this.$store.dispatch('user/keyWordLookUp', new KeyWordLookUpRequest(query, -1));
    }

    async mounted() {
        this.jobs = await this.$store.dispatch('job/lookUp');
    }

    async jobChanged(jobId) {
        if (jobId) {
            this.nodes = await this.$store.dispatch('node/jobNodes', jobId);
        } else {
            this.nodes = [];
        }
    }
}
